import React from "react"

const DeleteAccountInfo: React.FC = () => {
  return (
    <section className="condizioni-page">
      <div className="container-fluid">
        <div className="row">
          <div className="condizioni-head">
            <div className="condizioni-head-title">
              <h1>Istruzioni eliminazione account</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col col-sm col-md col-lg col-xl">
            <div className="condizioni-content">
              <div className="space-y-4">
                <h2 className="text-lg font-semibold">
                  Per effettuare l'eliminazione del proprio account occorre
                  seguire i seguenti step:
                </h2>

                <ol className="list-decimal list-inside space-y-4 ml-4">
                  <li>
                    <strong>Accesso all'account</strong>: Accedi al tuo account
                    utilizzando le tue credenziali.
                  </li>
                  <li>
                    <strong>Area riservata</strong>: Accedere alla pagina "Il
                    tuo account".
                  </li>
                  <li>
                    <strong>Eliminazione dell'account</strong>: In fondo alla
                    pagina, clicca su "cancella account" e successivamente
                    confermare l'eliminazione.
                  </li>
                  <li>Il vostro account è stato eliminato con successo.</li>
                </ol>

                <p>
                  Per ogni domanda o chiarimento è possibile inviare una e-mail
                  all'indirizzo{" "}
                  <a
                    href="mailto:info@eshoppingadvisor.com"
                    className="text-blue-600 hover:underline"
                  >
                    info@eshoppingadvisor.com
                  </a>{" "}
                  o contattarci via chat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DeleteAccountInfo
